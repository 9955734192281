import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components/macro"
import union from "lodash/union"
// import shuffle from "lodash/shuffle"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Thumbnail from "../components/thumbnail"

const Subhead = styled.h2`
  text-align: center;
  font-size: ${props => props.theme.fontSizes[2]};
  margin-top: 0;
  margin-bottom: 1rem;
  @media ${props => props.theme.screenSizes.tablet} {
    font-size: ${props => props.theme.fontSizes[4]};
    margin-bottom: 2rem;
  }
`
const ImageGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`
const Tags = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media ${props => props.theme.screenSizes.tablet} {
    margin: 2rem 0 0 0;
  }
`

const Tag = styled.span`
  font-size: ${props => props.theme.fontSizes[1]};
  border: solid 2px ${props => props.theme.colors.text};
  border-radius: 6px;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.5rem;
  cursor: pointer;
  &.activeTag {
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.text};
    box-shadow: 0 3px 0 ${props => props.theme.colors.background},
      0 4px 0 ${props => props.theme.colors.text},
      0 7px 0 ${props => props.theme.colors.background},
      0 8px 0 ${props => props.theme.colors.text};
  }
  @media ${props => props.theme.screenSizes.tablet} {
    &:hover {
      transition: background 0.2s ease, color 0.2s ease;
      color: ${props => props.theme.colors.background};
      background-color: ${props => props.theme.colors.text};
    }
  }
`

const IndexPage = ({ data }) => {
  const [activeTags, setActiveTags] = useState([])
  let tags = []
  data.allContentfulPhoto.edges.map(
    photo => (tags = union(tags, photo.node.tags))
  )
  return (
    <Layout>
      <SEO title="" />
      <Subhead>
        Tired of people seeing your “home office” on Zoom?
        <br />
        Teleport somewhere better.
      </Subhead>
      <Tags>
        {tags.map(tag => (
          <Tag
            key={tag}
            className={activeTags.includes(tag) ? "activeTag" : null}
            onClick={() =>
              activeTags.includes(tag)
                ? setActiveTags(activeTags.filter(e => e !== tag))
                : setActiveTags([...activeTags, tag])
            }
          >
            {tag}
          </Tag>
        ))}
      </Tags>
      <ImageGrid>
        {data.allContentfulPhoto.edges.map(photo => {
          if (
            activeTags.length === 0 ||
            (photo.node.tags &&
              photo.node.tags.some(r => activeTags.includes(r)))
          ) {
            return <Thumbnail key={photo.node.slug} photo={photo.node} />
          } else return null
        })}
      </ImageGrid>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulPhoto(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          sourceUrl
          source
          slug
          fullSizeImage
          description {
            description
          }
          tags
          thumbnail {
            localFile {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 400) {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  sizes
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
