import React, { useState } from "react"
import styled from "styled-components/macro"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import chroma from "chroma-js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import download from "downloadjs"

const PhotoDetails = styled.div`
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSizes[1]};
  text-shadow: 0 0 6px
    ${props => chroma(props.theme.colors.background).alpha(0.2)};
  border: solid 2px ${props => props.theme.colors.text};
  padding: 0.5rem;
  background: ${props => chroma(props.theme.colors.background).alpha(0.66)};
`
const PhotoTitle = styled.h3`
  font-size: ${props => props.theme.fontSizes[2]};
  margin: 0;
  padding: 0;
  text-align: center;
`
const PhotoSource = styled.div``
const PhotoDescription = styled.div``

const ThumbnailWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  &:hover {
    ${PhotoDetails} {
      transition: opacity 0.2s ease;
      opacity: 100;
    }
    img {
      filter: blur(3px);
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media ${props => props.theme.screenSizes.tablet} {
    width: 400px;
    max-width: 40%;
    margin: 1rem;
    &:last-child {
      margin: 1rem;
    }
  }
`
const ArrowIcon = styled.span`
  margin-left: 0.5rem;
  opacity: 0.5;
`

const CTAButton = styled.a`
  background: ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.background};
  text-shadow: none;
  font-weight: ${props => props.theme.fontWeights.black};
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: 1;
  border: none;
  border-radius: 3px;
  padding: 0.75rem 1rem;
  display: inline-block;
  margin-top: 1rem;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    ${ArrowIcon} {
      transition: opacity 0.2s ease;
      opacity: 1;
    }
  }
`

const StyledLink = styled.a`
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  font-weight: ${props => props.theme.fontWeights.bold};
  &:hover {
    transition: border 0.2s ease;
    border-bottom: solid 2px ${props => props.theme.colors.text};
  }
`
const Modal = styled.div`
  position: fixed;
  background-color: ${props =>
    chroma(props.theme.colors.background).alpha(0.8)};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  div {
    width: 75%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    background: ${props => props.theme.colors.background};
    border: solid 2px ${props => props.theme.colors.text};
    border-radius: 6px;
    box-shadow: 0 4px 0 ${props => props.theme.colors.background},
      0 6px 0 ${props => props.theme.colors.text},
      0 10px 0 ${props => props.theme.colors.background},
      0 12px 0 ${props => props.theme.colors.text},
      0 15px 30px 0 ${props => props.theme.colors.background};
    h2 {
      text-align: center;
    }
  }
  @media ${props => props.theme.screenSizes.tablet} {
    div {
      width: 400px;
    }
  }
`

const downloadImage = (e, url, slug, setModalVisible) => {
  e.preventDefault()
  trackCustomEvent({
    category: "Download Photo",
    action: "Download",
    label: slug,
  })
  fetch(url, {
    method: "GET",
  })
    .then(response => {
      return response.blob()
    })
    .then(blob => {
      download(blob, `teleport-to-${slug}`)
      setModalVisible(true)
    })
}

const Thumbnail = node => {
  const [showModal, setModalVisible] = useState(false)
  if (node?.photo?.thumbnail?.localFile?.childImageSharp?.fluid) {
    const { photo } = node
    const { fluid } = photo.thumbnail.localFile.childImageSharp
    return (
      <ThumbnailWrapper>
        {showModal && (
          <Modal onClick={() => setModalVisible(false)}>
            <div>
              <h2>Download Started</h2>{" "}
              <p>
                To add this background to Zoom, go to the “Virtual Background”
                panel of the app settings and click the{" "}
                <FontAwesomeIcon icon={["far", "plus-square"]} /> button to add
                your image.{" "}
                <StyledLink
                  href="https://support.zoom.us/hc/en-us/articles/210707503-Virtual-Background"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </StyledLink>
              </p>
              <CTAButton>Keep Browsing</CTAButton>
            </div>
          </Modal>
        )}
        <Img fluid={fluid} alt={photo.slug} />
        <PhotoDetails>
          <PhotoTitle>{photo.title}</PhotoTitle>
          {photo.source && (
            <PhotoSource>
              by{" "}
              {photo.sourceUrl ? (
                <StyledLink href={photo.sourceUrl} target="_blank">
                  {photo.source}
                </StyledLink>
              ) : (
                <strong>{photo.source}</strong>
              )}
            </PhotoSource>
          )}
          {photo.description && (
            <PhotoDescription>{photo.description.description}</PhotoDescription>
          )}
          <CTAButton
            onClick={e =>
              downloadImage(
                e,
                photo.fullSizeImage,
                photo.slug,
                setModalVisible
              )
            }
          >
            Teleport here
            <ArrowIcon>
              <FontAwesomeIcon icon={["fas", "long-arrow-right"]} />
            </ArrowIcon>
          </CTAButton>
        </PhotoDetails>
      </ThumbnailWrapper>
    )
  } else {
    return null
  }
}

export default Thumbnail

Thumbnail.propTypes = {
  photo: PropTypes.object.isRequired,
}
